<template>
  <div class="bankers-assigned p-2">
    <p class="font-bold mb-2">Bankers Allocated</p>

    <!-- Loading State -->
    <div v-if="isLoading" class="list px-2 py-1">
      <banker
        isLoading
        :banker="{ name: '', bank: '' }"
        v-for="n in 4"
        :key="n"
      ></banker>
    </div>
    <!-- List  -->
    <div v-else class="list px-2 py-1">
      <div v-if="bankers.length < 1" class="banker-empty">
        <NoData message="No bankers available"></NoData>
      </div>
      <DynamicScroller v-else :items="bankers" :min-item-size="120">
        <template v-slot="{ item, active }">
          <DynamicScrollerItem
            :item="item"
            :active="active"
            :data-index="item.id"
            :size-dependencies="[item.name, item.bank]"
            class="px-2"
          >
            <banker
              :isRemove="bankers.length > 1"
              :banker="item"
              @remove="
                (banker) => {
                  $emit('remove', banker);
                }
              "
            >
            </banker>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
      <InfiniteLoading v-if="isEdit" @infinite="infiniteScrollHandler">
        <span slot="no-more"></span>
      </InfiniteLoading>
    </div>
  </div>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import InfiniteLoading from "vue-infinite-loading";

import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
  components: {
    DynamicScroller,
    DynamicScrollerItem,
    InfiniteLoading,
    Banker: () => import("./Banker"),
    NoData: () => import("@/components/GlobalComponents/NoData/NoData")
  },
  mixins: [],
  props: {
    bankers: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    infiniteScrollHandler(state) {
      this.$emit("infinite-scroll", state);
    }
  }
};
</script>

<style lang="scss">
.bankers-assigned {
  .list {
    max-height: 300px;
    overflow-y: auto;
  }
}
</style>
